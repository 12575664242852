<template>
  <b-card no-body>
    <b-card-header>
      Видеоуроки
    </b-card-header>
    <b-card-body :class="{'loading' : loading}">
      <b-row>
        <video-lessons-view-list-item
          v-for="item in data"
          :key="item.id"
          :data="item"
        />
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import Vue from 'vue';
import notifications from '../../components/mixins/notifications';
import {
  contractorVideoLessonsList,
} from '../../services/api';
import moment from 'moment';
import VideoLessonsViewListItem from '@/views/VideoLessons/VideoLessonsViewListItem';


Vue.directive('add-class-hover', {
  bind(el, binding, vnode) {
    const {value=''} = binding;
    el.addEventListener('mouseenter', ()=> {
      console.log(11);
      el.classList.add(value);
    });
    el.addEventListener('mouseleave', ()=> {
      console.log(12);
      el.classList.remove(value);
    });
  },
  unbind(el, binding, vnode) {
    el.removeEventListener('mouseenter');
    el.removeEventListener('mouseleave');
  },
});
export default {
  name: 'ContractorVideoList',
  components: {
    VideoLessonsViewListItem,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      loading: false,
      data: [],

    };
  },
  mounted() {
    this.getLessonsData();
  },
  methods: {
    async getLessonsData() {
      this.loading = true;
      const response = await contractorVideoLessonsList({limit: 1000});
      if (response && response.status === 200) {
        console.log(111);
        this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
        this.data = {...response.data.items};
      }
      this.loading = false;
    },

  },
};
</script>

<style scoped>

</style>
